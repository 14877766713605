









import { Component, Vue } from "vue-property-decorator";
import SystemIcons from "@/svg/SystemIcons.vue";
import PromotionTitle from "./components/PromotionTitle.vue";
import PromotionTemplate from "./components/PromotionTemplate.vue";

export default {
    name: "CasinoSection",
    components: {
        SystemIcons,
        PromotionTemplate,
        PromotionTitle
    },
    methods: {
        onClickImage() {
            window.eventEmitter.emit('pp.showLogin', "Casino");
        }
    },
};
