<template>
  <div class="promotionTitle">
    <h2>{{promotionTitle}}</h2>
    <div class="promotionTitle-more" :class="linkGA">
      <span>{{linkText}}</span>
      <svg class="svgIcon"><use xlink:href="#systemIcon_arrowRight"></use></svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromotionTitle",
  props: {
    promotionTitle: {
      type: String,
      default: function() {
        return "Promotion Title";
      }
    },
    linkText:{
      type: String,
      default: function(){
        return "Link Text";
      }
    },
    linkGA:{
      type: String,
      default: function(){
        return "Link GA";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/components/common/promotionTitle";
</style>
