import Vue from 'vue';
import CasinoBsi from './Casino-bsi.vue';
import i18n from '@/plugins/i18n';

Vue.config.productionTip = true;

new Vue({
    i18n,
    render: h => h(CasinoBsi)
}).$mount('#casino-section');
