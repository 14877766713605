import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';

import EN from './i18n/en/lang';
import ZH_CN from './i18n/zh_cn/lang';
import ID_ID from './i18n/id_id/lang';
import TH_TH from './i18n/th_th/lang';
import KO_KR from './i18n/ko_kr/lang';
import JA_JP from './i18n/ja_jp/lang';

//import eventEmitter from 'events';

Vue.use(VueI18n);


const locale = Cookies.get('LanguageType') || 'EN'

const i18n = new VueI18n({
    locale,
    fallbackLocale: "EN",
    messages:{EN, ZH_CN, ID_ID, TH_TH, KO_KR, JA_JP}
});

window.eventEmitter.on(
    'product.updateLanguage',
    function(languageType: string){
        i18n.locale=languageType;
});

export default i18n