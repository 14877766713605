<template>
  <div class="promotionTemplate" :class="{'_landscape':isLandscape}">
    <div class="promotionTemplate-mainItem" :class="promotionItems.mainItem.itemGA">
      <img :title="promotionItems.mainItem.itemName" :src="promotionItems.mainItem.itemImg">
    </div>
    <div class="promotionTemplate-otherItems">
      <div
        v-for="(item,index) in promotionItems.otherItems"
        :key="index"
        class="promotionTemplate-item"
        :class="item.itemGA"
      >
        <img :src="item.itemImg">
        <div v-if="item.itemName" class="promotionTemplate-item-name">{{item.itemName}}</div>
      </div>
    </div>
  </div>
  
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: "PromotionTemplate",
  props: {
    isLandscape: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
  },
  data: function () {
    return {
      language: Cookies.get('LanguageType') || 'EN'
    }
  },
  computed: {
    supportLanguage: function() {
      const imgLanguage = ["ZH_CN", "ID_ID", "TH_TH", "KO_KR","JA_JP"]
      if (imgLanguage.includes(this.language)) {
         return this.language;
      }
      return "EN"
    },
    promotionItems: function () {
      return {
            mainItem: {
              itemName: "",
              itemImg: require(`@/assets/images/LC_ASI_GameIcon_Bac_${this.supportLanguage}.jpg`),
              itemUrl: " ",
              itemGA: "JARVIS_LandingPage_LiveCasino_Btn_Baccarat"
            },
            otherItems: [
              {
                itemName: "",
                itemImg: require(`@/assets/images/LC_ASI_GameIcon_Rou_${this.supportLanguage}.jpg`),
                itemUrl: " ",
                itemGA: "JARVIS_LandingPage_LiveCasino_Btn_Roulette"
              },
              {
                itemName: "",
                itemImg: require(`@/assets/images/LC_ASI_GameIcon_SB_${this.supportLanguage}.jpg`),
                itemUrl: " ",
                itemGA: "JARVIS_LandingPage_LiveCasino_Btn_SicBo"
              }
            ]
       }
    }
  },
  methods: {
    changeLanguageType: function(newLanguage) {
      this.language = newLanguage;
    }
  },
  created() {
    window.eventEmitter.on(
    'product.updateLanguage',
    this.changeLanguageType
    );
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/components/common/promotionTemplate";
</style>
